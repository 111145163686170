import React, { useEffect, useState } from "react";
import "./Style/dashboard.css";
import "../../App.css";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import { Feedback } from "./Feedback";
import SideBar from "../Components/SideBar/SideBar";
import TextLinkExample from "../Components/NavBar/NavBar";
import { useContext } from "react";
import { SidebarContext } from "../../context/SidebarContext";
import LogoutCard from "../Components/Card/LogoutCard";
import Footer from "../Components/Footer/Footer";
import { useProfileInfoQuery } from "../../redux/API/portfolioApi";
import { useGetDataQuery } from "../../redux/API/dashboardApi";
import {
  calculateCurretValue,
  calculateFundInvested,
  formatDate,
  formatIndianNumber,
} from "../../Constant/utils";
import DoughnutChart from "../Components/Card/DoughnutChart";
import BarChart from "../Components/Card/BarChart";

const DashBoard = () => {
  const { isSiderbaropen } = useContext(SidebarContext);
  const [showLogout, setShowLogout] = useState(false);
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [doughnutChartData, setDoughnutChartData] = useState({ labels: [], datasets: [] });
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userId = userData?.validateEmail?._id;
  const getProfile = useProfileInfoQuery();
  const { data } = useGetDataQuery(userId);

  useEffect(() => {
    if (getProfile.isSuccess && !getProfile.isError && !getProfile.isLoading) {
      localStorage.setItem("clientCode", getProfile?.data?.user?.clientcode)
    }
  }, [getProfile]);


  // Current Portfolio Value
  const curretValue = calculateCurretValue(data);

  // My Fund Invested
  const fundInvested = calculateFundInvested(data);


  //Total Profit
  const ActualFund = data?.data?.reduce(
    (sum, item) => sum + item?.avg * item.qty,
    0
  );
 
  // Absolute Return
  const result = (curretValue - (fundInvested + ActualFund)) / ActualFund

  // Holding Period 
  const today = new Date(); 
  today.setHours(0, 0, 0, 0); // Ensure time part is zeroed
  let sumTransactions = 0;
  let totalWeight = 0;
  
  data?.investment?.forEach(item => {
      const transactionDate = new Date(item.date);
      transactionDate.setHours(0, 0, 0, 0); // Ensure time part is zeroed
      const transactionAmount = item.transacation;
  
      // Calculate the difference in years
      const timeDifferenceInYears = (today - transactionDate) / (365 * 24 * 60 * 60 * 1000); 
      
      // Calculate weight
      const weight = timeDifferenceInYears * transactionAmount;
  
      // Add to sum of positive transactions
      if (transactionAmount > 0) {
          sumTransactions += transactionAmount;
      }
      
      // Add to total weight
      totalWeight += weight;
  });
  
  // Calculate holding period in years
  const holdingPeriodInYears = totalWeight / sumTransactions;
  
  // Convert holding period to months
  const holdingPeriodInMonths = holdingPeriodInYears * 12;


  





  useEffect(() => {
    if (data?.data) {
      const currentValue = data.data.reduce((acc, result) => {
        const currentprice = result?.stocklist[0]?.currentprice;
        return acc + (currentprice ? currentprice * result.qty : 0);
      }, 0);

      // Calculate the percentage of each industry in currentValue
      const industryPercentages = data.data.reduce((acc, result) => {
        const industry = result?.stocklist[0]?.industry;
        const currentprice = result?.stocklist[0]?.currentprice;
        const value = currentprice ? currentprice * result.qty : 0;

        if (industry) {
          if (!acc[industry]) {
            acc[industry] = 0;
          }
          acc[industry] += value;
        }

        return acc;
      }, {});

      // Convert industryPercentages to an array and calculate the percentage
      const industryArray = Object.keys(industryPercentages).map(
        (industry) => ({
          industry,
          percentage: (industryPercentages[industry] / currentValue) * 100,
        })
      );

      // Sort by currentprice and take the top 10
      const top10Industries = industryArray
        .sort((a, b) => b.percentage - a.percentage)
        .slice(0, 10);

      // Prepare data for the chart
      const labels = top10Industries.map((item) => item.industry);
      const percentages = top10Industries.map((item) => item.percentage);

      // Set chart data
      setChartData({
        labels: labels,
        datasets: [
          {
            label: "Percentage of Current Value",
            data: percentages,
            backgroundColor: "rgb(109, 214, 214)",
            borderColor: "rgb(109, 214, 214)",
            borderWidth: 1,
            barThickness: 25,
            maxBarThickness: 30, 
          },
        ],
      });
    }
  }, [data]);

  useEffect(() => {
    if (data?.data) {
      // Count the number of large cap and small cap stocks
      const capCount = data.data.reduce((acc, result) => {
        const marketCap = result?.stocklist[0]?.marketcapital;
        if (marketCap) {
          if (!acc[marketCap]) {
            acc[marketCap] = 0;
          }
          acc[marketCap] += 1;
        }
        return acc;
      }, {});

      // Calculate total count
      const totalCount = Object.values(capCount).reduce((acc, count) => acc + count, 0);

      // Calculate percentage
      const capPercentages = Object.keys(capCount).map(cap => ({
        label: `${cap} ${((capCount[cap] / totalCount) * 100).toFixed(2)}%`,
        percentage: (capCount[cap] / totalCount) * 100,
      }));

      // Prepare data for the doughnut chart
      const labels = capPercentages.map(item => item.label);
      const percentages = capPercentages.map(item => item.percentage);

      // Set chart data
      setDoughnutChartData({
        labels: labels,
        datasets: [{
          data: percentages,
          backgroundColor: [
            '#ea7e90', 
            '#a84926',
            '#e28743'
          ],
          hoverOffset: 4
        }]
      });
    }
  }, [data]);


  return (
    <>
      <div className="App">
        <SideBar />
        <div className={!isSiderbaropen ? "pages" : "pages-change"}>
          <TextLinkExample setShowLogout={setShowLogout} />
          <div className="HomeCard">
            <div className="col-md mx-4">
              <div className="row ">
                <div className="col-xl-4 col-md-6 ">
                  <div className="card static-1 row-1">
                    <div className="mx-4">
                      <h5 className="card-title">Current Portfolio Value</h5>
                      <h2 className="Values">
                        Rs.{formatIndianNumber(curretValue)}
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-md-6  ">
                  <div className="card static-2 row-1">
                    <div className="mx-4">
                      <h5 className="card-title">My Fund Invested</h5>
                      <h2 className="Values">Rs.{fundInvested}</h2>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-md-6  ">
                  <div className="card static-3 row-1">
                    <div className="mx-4">
                      <h5 className="card-title">Total Profit</h5>
                      <h2 className="Values">{curretValue - (fundInvested + ActualFund)}</h2>
                    </div>
                  </div>
                </div>
               
                <div className="col-xl-3 col-md-6 pe-lg-0 ">
                  <div className="card static-4 row-2">
                    <div className="mx-4">
                      <h5 className="card-title">CAGR</h5>
                      <h2 className="Values">
                        {data?.CAGR?.CAGR !== "#NUM!"
                          ? Number(data?.CAGR?.CAGR).toFixed(2)
                          : "---"}
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6 pe-lg-0">
                  <div className="card static-5 row-2">
                    <div className="mx-4">
                      <h5 className="card-title">Absolute Return</h5>
                      <h2 className="Values">{(result).toFixed(2)}</h2>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6  pe-lg-0">
                  <div className="card static-3 row-2">
                    <div className="mx-4">
                      <h5 className="card-title">Holding Period</h5>
                      <h2 className="Values">{holdingPeriodInMonths.toFixed(2)} Month</h2>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6  ">
                  <div className="card static-6 row-2">
                    <div className="mx-4">
                      <h5 className="card-title">Account Opening Date</h5>
                      <h2 className="Values">
                        {formatDate(getProfile?.data?.user?.approvaldate)}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="PieChart-Table row gy-3 mx-4">
              <div className=" PieChart col-lg-8">
                <div className="row gy-3">
                  <div className="PieChartTitle d-flex m-2">
                    <h5>PortFolio Strategy: </h5>
                    <h5
                      style={{
                        backgroundColor: "#9DC913",
                        padding: "5px 10px",
                        borderRadius: "4px",
                        color: "white",
                      }}
                    >
                      {" "}
                      E-50 Strategy{" "}
                    </h5>
                  </div>
                  <div className="col-md-5">
                    <DoughnutChart 
                    doughnutChartData={doughnutChartData}
                    />
                  </div>
                  <div className="col-md-7 px-lg-1">
                    <BarChart chartData={chartData} />
                  </div>
                  <div
                    className="PieChartFooter mt-4"
                    style={{
                      textAlign: "center",
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: "#78766f",
                    }}
                  >
                    <div className="text-white py-1" style={{backgroundColor: "#9DC913"}}>
                      No. of Stocks in Portfolio:{" "}
                     
                        <strong>{data?.data?.length}</strong>
                     
                    </div>
                  </div>
                </div>
              </div>
              <div className="card DashBoardTable col-lg-4 py-3">
                <h4>Fund Inflow</h4>
                <MDBTable hover striped>
                  <MDBTableHead dark>
                    <tr>
                      <th scope="col">S.No.</th>
                      <th scope="col">Date</th>
                      <th scope="col">Amount</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {data &&
                      data?.investment?.map((i, index) => (
                        <tr key={i?._id}>
                          <th scope="row">{index + 1}</th>
                          <td>{formatDate(i?.date?.slice(0, 10))}</td>
                          <td>{formatIndianNumber(i?.transacation)}</td>
                        </tr>
                      ))}
                  </MDBTableBody>
                </MDBTable>
              </div>
            </div>
          </div>
          <Footer />
        </div>
        <Feedback />
      </div>
      {showLogout && <LogoutCard setShowLogout={setShowLogout} />}
    </>
  );
};

export default DashBoard;
