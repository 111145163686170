import React, { useEffect, useState } from 'react'
import './Style/support.css'
import { Business, PhoneAndroid } from '@mui/icons-material'
import { FloatingLabel, Form} from 'react-bootstrap'
import { Feedback } from './Feedback';
import SideBar from '../Components/SideBar/SideBar';
import TextLinkExample from '../Components/NavBar/NavBar';
import { useContext } from 'react';
import { SidebarContext } from '../../context/SidebarContext';
import LogoutCard from '../Components/Card/LogoutCard';
import Footer from '../Components/Footer/Footer';
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import toast, { Toaster } from 'react-hot-toast';
import { useCreateConversationMutation, useCreateSupportMutation, useGetConversationQuery, useGetSupportQuery } from '../../redux/API/supportApi';
import { formatDate } from '../../Constant/utils';
import { Link } from 'react-router-dom';
const Support = () => {
  const { isSiderbaropen } = useContext(SidebarContext) 
  const [showLogout, setShowLogout] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userId = userData?.validateEmail?._id;
  const userName = userData?.validateEmail?.name;
  const [inpuData, setInputData] = useState({
    subject: "",
    description: "",
    createdby:"user",
    userId: userId,
  });
  const [error, setError] = useState("")
  const [message, setMessage] = useState("")
  const [conversationId, setConversationId] = useState(null)
  const [postData] = useCreateSupportMutation()
  const [postMessage] = useCreateConversationMutation()
  const {data: supportList, refetch: refetchSupportList} = useGetSupportQuery(userId)
  const {data: conversationList, refetch: refetchConversationList} = useGetConversationQuery(conversationId)
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inpuData, [name]: value });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    if(inpuData.subject === "" || inpuData.description === ""){
      setError("All Feilds are required!")
      setTimeout(() => {
        setError("")
      }, 3000);
      return;
    }
    try {
      postData(inpuData).then((res) => {
        if(res?.data?.success){ 
          toast.success("Support Created Successfully") 
          setInputData({
            subject: "",
            description: "",
          });
          refetchSupportList()
        }
        else toast.error("Something went wrong. Please try later")
      })
    } catch (error) {
      toast.error(error)
    }
  }

  const handleModal = (id) => {
    setConversationId(id)
    setShowModal(true)
    refetchConversationList()
  }


  const handleConversation = (e) => {
    e.preventDefault();
    try {
      const body = {
        conversationId: conversationId,
        message: message,
        sendBy: 'user',
        name: userName,
        senderId: userId,
      }
      postMessage(body).then((res) => {
        if(res?.data?.success){ 
         setMessage("")
         refetchConversationList()
        }
        else toast.error("Something went wrong. Please try later")
      })
    } catch (error) {
      toast.error(error)
    }
  }

  useEffect(() => {
    let intervalId;

    if (showModal) {
        intervalId = setInterval(() => {
            refetchConversationList();
        }, 7000);
    }

    return () => {
        if (intervalId) {
            clearInterval(intervalId); 
        }
    };
}, [showModal, refetchConversationList]);


  return (
    <>
    <div className="App">
    <Toaster
        position="top-right"
        />
    <SideBar />
    <div className={!isSiderbaropen ? 'pages' : 'pages-change'}>
    <TextLinkExample
   setShowLogout={setShowLogout}
    />
    <div className=' Support p-2'>
      <div className='support1 mx-4'>
        <div className='supportHeadImage my-2 mx-4 py-2 px-2'>
          <h2>Support</h2>
          <img src={require("../Components/photos/Animation - 1697612946436.gif")} alt='' /> 
        </div>
        <div className='support1card'>
          <div className="row">
            <div className="col-sm-6">
              <div className="card">
                <h3 className="card-title my-2"><i className="fa-solid fa-phone-volume fa-shake " style={{ color: " #ffffff" }}></i>Contact</h3>
                <div className="card-body justify-content-center">
                  <p className="card-text text-center" ><PhoneAndroid />+91-8800308006</p>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="card">
                <h3 className="card-title my-2"><i className="fa-solid fa-location-dot fa-flip mx-3" style={{ color: " #ffffff" }}></i>Address</h3>
                <div className="card-body">
                  <p className="card-text"> <Business />305, Tower 4, Assotech Business Cresterra, Sector-135, Noida–201304</p>
                  {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='support2card '>
          <div className='support2cardHead'>
           <span> Get In Touch</span> 
            <img src={require("../Components/photos/Animation - 1697786461745.gif")} alt=''/>
          </div>
          <div className='support2cardform my-4'>
            <form action="" onSubmit={handleSubmit}>
              <p className='text-danger'>{error}</p>
            <span><i className="fa-solid fa-ticket"></i> Subject</span>
            <FloatingLabel controlId="floatingInput" label="Subject" className="mb-3" >
              <Form.Control type="text" placeholder="Subject"  name='subject' value={inpuData.subject} onChange={handleChange}/>
            </FloatingLabel>
            <span><i className="fa-solid fa-comment-dots mt-3"></i> Message</span>
            <FloatingLabel controlId="floatingTextarea2" label="Message" className="mb-3">
              <Form.Control
                as="textarea"
                placeholder="Leave a comment here"
               name='description'
               value={inpuData.description}
               onChange={handleChange}
              />
            </FloatingLabel>
            <button type="submit" className="btn my-2"> <i className="fa-solid fa-paper-plane"></i> Send</button>
            </form>
          </div>
        </div>
        <div className='support2card '>
       
                <MDBTable hover striped>
                  <MDBTableHead dark>
                    <tr>
                      <th scope="col">S.No.</th>
                      <th scope="col">Ticket No.</th>
                      <th scope="col">Date</th>
                      <th scope="col">Status</th>
                      <th scope="col">View</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                   {
                    supportList?.data?.map((i, index) => (
                        <tr key={i?._id}>
                          <th scope="row">{index+1}</th>
                          <td>{i?.ticket}</td>
                          <td>{formatDate(i?.createdAt)}</td>
                          <td>{i?.status === 0 ? 'Pending' : 'Processing'}</td>
                          <td><Link onClick={() => handleModal(i?._id)}><i className="fa-solid fa-eye"></i></Link></td>
                        </tr>
                         
                    ))
                  }
                  </MDBTableBody>
                </MDBTable>
            
        </div>
      </div>
    </div>
    <Footer/>
    </div>
      <Feedback/>
     {
      showModal && (

     
 <div className="modal-wrapper">
  <form className="chat-card" onSubmit={handleConversation}>
    <div className="chat-header d-flex justify-content-between">
      <span>Chat</span>
      <Link onClick={() => setShowModal(false)}><span><i className="fa-solid fa-xmark fs-5"></i></span></Link>
    </div>
      <div className="chat-window">
        <ul className="message-list p-2 pb-3">
          {
            conversationList && conversationList?.result?.map((result) => (
          <li key={result?._id} className={` bg-dark-subtle  p-1 rounded-1 w-75 msg-text mb-2  ${result?.sendBy === "admin" ? "" : "reciver"}`}>
            <span className='d-block'>{result?.message}</span>
            <span className='chat-date d-block text-end'>{result?.name}</span>
            <span className='chat-date d-block text-end'>{formatDate(result?.createdAt)}</span>
          </li>
            ))
          }
        </ul>
      </div>
      <div className="chat-input">
          <input type="text" className="message-input" placeholder="Type your message here" value={message} onChange={(e) => setMessage(e.target.value)}/>
          <button className="send-button" disabled={message === ""}>Send</button>
      </div>
    </form>
  </div>
     )
    }
      </div>
       {
        showLogout && (
          <LogoutCard
          setShowLogout={setShowLogout}
          />
        )
      }
      
      </>
  )
}

export default Support
