import React, { useState } from "react";
import "./Style/referus.css";
import { FloatingLabel, Form } from "react-bootstrap";
import { Feedback } from "./Feedback";
import SideBar from "../Components/SideBar/SideBar";
import TextLinkExample from "../Components/NavBar/NavBar";
import { useContext } from "react";
import { SidebarContext } from "../../context/SidebarContext";
import LogoutCard from "../Components/Card/LogoutCard";
import Footer from "../Components/Footer/Footer";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import { useGetReferDataQuery, useReferUsMutation } from "../../redux/API/referApi";
import toast, { Toaster } from "react-hot-toast";
import { formatDate } from "../../Constant/utils";
const ReferUs = () => {
  const { isSiderbaropen } = useContext(SidebarContext);
  const [showLogout, setShowLogout] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userId = userData?.validateEmail?._id;
  const [inpuData, setInputData] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
    userid: userId,
  });
  const [error, setError] = useState("")
  const [postData] = useReferUsMutation();
  const { data } = useGetReferDataQuery(userId)
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inpuData, [name]: value });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    if(inpuData.name === "" || inpuData.email === "" || inpuData.message === "" || inpuData.mobile === ""){
      setError("All Feilds are required!")
      setTimeout(() => {
        setError("")
      }, 3000);
      return;
    }
    try {
      postData(inpuData).then((res) => {
        if(res?.data?.success){ 
          toast.success("Refered Successfully") 
          setInputData({
            name: "",
            email: "",
            mobile: "",
            message: "",
            userid: userId,
          });
        }
        else toast.error("Something went wrong. Please try later")
      })
    } catch (error) {
      toast.error(error)
    }
  }


  return (
    <>
      <div className="App"> 
      <Toaster
        position="top-right"
        />
        <SideBar />
        <div className={!isSiderbaropen ? "pages" : "pages-change"}>
          <TextLinkExample setShowLogout={setShowLogout} />
          <div className=" ReferUs">
            <div className="referus1">
              <div className="referushead mx-4 my-4 py-2">
                <h2>Refer Us</h2>
                <i className="fa-solid fa-user-group fa-2xl"></i>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <form className="container1 ms-3" onSubmit={handleSubmit}>
                      <p className="text-danger">{error}</p>
                      <div className="row pt-2">
                        <div className="col">
                          <span>
                            <i className="fa-solid fa-user"></i> Name
                          </span>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Name"
                            className="mb-3"
                          >
                            <Form.Control
                              type="name"
                              placeholder="Enter your Name"
                              name="name"
                              value={inpuData.name}
                              onChange={handleChange}
                            />
                          </FloatingLabel>
                        </div>
                        <div className="col">
                          <span>
                            <i className="fa-solid fa-phone"></i> Phone
                          </span>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Phone"
                            className="mb-3"
                          >
                            <Form.Control
                              type="text"
                              maxLength={10}
                              placeholder="+91-----"
                              name="mobile"
                              value={inpuData.mobile}
                              onChange={handleChange}
                            />
                          </FloatingLabel>
                        </div>
                      </div>
                      <span>
                        <i className="fa-solid fa-envelope"></i> Email
                      </span>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Email address"
                        className="mb-3"
                      >
                        <Form.Control
                          type="email"
                          placeholder="name@example.com"
                          name="email"
                          value={inpuData.email}
                          onChange={handleChange}
                        />
                      </FloatingLabel>
                      <span>
                        <i className="fa-solid fa-comment-dots"></i> Message
                      </span>
                      <FloatingLabel
                        controlId="floatingTextarea2"
                        label="Message"
                        className="mb-3"
                      >
                        <Form.Control
                          as="textarea"
                          placeholder="Leave a comment here"
                          style={{ height: "100px" }}
                          name="message"
                          value={inpuData.message}
                          onChange={handleChange}
                        />
                      </FloatingLabel>
                      <button type="submit" className="btn my-2">
                        <i className="fa-solid fa-paper-plane"></i> Send
                      </button>
                    </form>
                  </div>
                  <div className="col-lg-6">
                    <MDBTable hover striped
                     style={{
                      fontSize: "14px",
                    }}
                    >
                      <MDBTableHead dark>
                        <tr>
                          <th scope="col">S.No.</th>
                          <th scope="col">Name</th>
                          <th scope="col">Date</th>
                          <th scope="col">ReferId</th>
                        </tr>
                      </MDBTableHead>
                      <MDBTableBody>
                        {
                          data?.data?.map((i, index) => (
                        <tr key={i?._id}>
                          <td>{index+1}</td>
                          <td>{i?.name}</td>
                          <td>{formatDate(i?.createdAt)}</td>
                          <td>{i?._id}</td>
                        </tr>
                           
                          ))
                        }
                      </MDBTableBody>
                      
                    </MDBTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
        <Feedback />
      </div>
      {showLogout && <LogoutCard setShowLogout={setShowLogout} />}
    </>
  );
};

export default ReferUs;
